import React from 'react';
import styled from 'styled-components';
import { createTheme, TextField, Box, Link, DialogTitle, TablePagination, TableSortLabel, TableCell, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { MailOutline, Mail } from '@mui/icons-material';
import cardWhiteImage from '@images/card-white.webp';
import wallpaperBackground from '@images/wallpaper_background.svg';
import wallpaperBackground_light from '@images/wallpaper_background_light.webp';
import logo_big from '@images/logo.png';
import logo_small from '@images/logo.png';

export const HomeAccountBalance = {
  fontWeight: 500,
  color: 'white',
  fontSize: '0.875rem',
  fontFamily: 'Poppins',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

export const PageTitle = styled(Box)({
  color: 'rgb(105, 92, 119)',
  fontWeight: 500,
  marginBottom: '25px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  '& ::first-letter': {    // Added this style
    textTransform: 'uppercase'
  }
});

export const HomeCardBgPicture = {
  backgroundImage: `url(${wallpaperBackground})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: 'white',
  padding: '30px',
  height: '300px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const list_with_bottom_border = {
  color: 'rgb(105, 92, 119)',
  fontSize: '0.875rem',
  fontFamily: 'Poppins',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  paddingBottom: '10px',
  borderBottom: '1px solid #f0f0f0',
  width: '100%'
};

export const updatePasswordStyle = {
  ...list_with_bottom_border,
  cursor: 'pointer',
  color: '#FFFFFF',
  backgroundColor: '#374F8F',
  padding: '8px 16px',
  borderRadius: '4px',
  display: 'inline-flex',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '#4285F4',
  },
  width: 'calc(100% - 24px)'
};

export const theme = createTheme({
  palette: {
    primary: {
      main: '#374F8F',
    },
    topmenu_icons: {
      main: '#374F8F',
    },
    leftmenu_icons: {
      main: '#374F8F',
    },
    leftmenu_sections: {
      main: '#637381',
    },
    leftmenu_selected_background: {
      main: '#e0f0f9',
    },
    leftmenu_selected_text: {
      main: 'rgb(105, 92, 119)',
    },
    leftmenu_not_selected_text: {
      main: 'rgb(105, 92, 119)',
    },
    DrawerReduceButton: {
      main: '#FFFFFF',
    },
    DrawerReduceButton_hover: {
      main: '#F0f0f0',
    },
  },
  background: {
    default: '#FAFAFA',
    paper: '#FAFAFA',
  },
  components: {
    MuiTablePagination: {
      styleOverrides: {
        root: {
          '& .MuiTablePagination-select': {
            '-webkit-appearance': 'none',
            '-moz-appearance': 'none',
            appearance: 'none',
          },
          '& .MuiTablePagination-selectIcon': {
            display: 'none',
          },
          '& .MuiNativeSelect-select::-ms-expand': {
            display: 'none',
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#374F8F',
          textDecorationColor: '#374F8F',
          '&:hover': {
            color: '#080708',
            textDecorationColor: '#080708',
          },
        }
      }
    },
    MuiBox: {
      styleOverrides: {
        root: {
          color: '#374F8F',
          fontFamily: 'Galano Grotesque, sans-serif',
          fontSize: '0.875rem',
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: '48px',
        },
        input: {
          height: '48px',
          padding: '0 14px',
          display: 'flex',
          alignItems: 'center',
          '&::placeholder': {
            color: '#CCCCCC',
            opacity: 1,
            fontFamily: 'Galano Grotesque, sans-serif',
          },
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            height: '48px',  // Changed from 40px
            padding: '0 14px',
            display: 'flex',
            alignItems: 'center',
          },
          '& .MuiInputBase-root': {
            height: '48px',  // Changed from 50px
            borderColor: '#374F8F',
            '&:hover': {
              borderColor: '#219ebc',
            },
            '&.Mui-focused': {
              borderColor: '#219ebc',
            }
          },
          '& .MuiInputBase-input::placeholder': {
            color: '#A3B5C0',
            fontFamily: 'Galano Grotesque, sans-serif',
          },
          '& .MuiInputLabel-root': {
            top: '0px',
          },
          '& .MuiInputLabel-shrink': {
            top: 0,
          },
          '& .MuiFormLabel-root': {
            padding: '0 5px',
            backgroundColor: 'white',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#374F8F',
              borderWidth: '1px',
            },
            '&:hover fieldset': {
              borderColor: '#695C77',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#695C77',
            }
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          height: '48px',
          '& .MuiTypography-root': {
            color: '#374F8F',
          },
          '&.Mui-focused .MuiTypography-root': {
            color: '#374F8F',
          },
          '& .MuiSelect-select .MuiTypography-root': {
            color: '#374F8F',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#374F8F',
            borderWidth: '1px',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#695C77',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#695C77',
          }
        },
        select: {
          height: '48px',
          padding: '8px 14px',
          fontSize: '1rem',
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          color: '#374F8F',
          '& .MuiTypography-root': {
            color: '#374F8F',
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#695C77',
          fontSize: '0.875rem',
          minHeight: '48px',
          padding: '8px 14px',  // Adjusted padding
          display: 'flex',
          alignItems: 'center',
          gap: '12px',  // Added gap between icon and text
          '& .MuiSvgIcon-root': {  // Icon styling
            marginRight: '8px',
            fontSize: '20px',
          },
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.12)',
            }
          }
        }
      }
    },
    MuiListItemIcon: {  // Added for better icon alignment
      styleOverrides: {
        root: {
          minWidth: '24px',
          marginRight: '8px',
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '48px',
        },
        input: {
          height: '48px',
          padding: '0 14px',
          fontSize: '1rem',
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          color: '#695C77', // Default color when input is empty
          fontWeight: 300,
          '&.Mui-focused, &.MuiFormLabel-filled': {
            fontWeight: 500,
            color: '#374F8F', // Color when label is shrunk (input focused or filled)
          },
        },
        outlined: {
          transform: 'translate(14px, 14px)',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
          },
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          fontFamily: 'Galano Grotesque, sans-serif',
          color: 'rgb(105, 92, 119)',
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          fontFamily: 'Galano Grotesque, sans-serif',
          color: 'rgb(105, 92, 119)',
          alignItems: 'center',
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#FFFFFF',
          borderRight: '1px dotted #E0E0E0',
          width: 220,
          '&.reduced-drawer': {
            width: 80,
          },
          padding: '10px',
          zIndex: 40,
        },
        paperAnchorRight: {
          width: '350px',
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)'
          }
        }
      }
    },
    MuiListItemText: {         // Added to ensure text styling is consistent
      styleOverrides: {
        primary: {
          fontSize: '0.875rem',
          fontWeight: 400,
          color: 'inherit'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#374F8F',
          color: '#FFFFFF',
          borderRadius: '8px',
          textTransform: 'none',
          fontWeight: 600,
          fontSize: '15px',
          height: '48px',
          minHeight: '40px',
          lineHeight: 1,
          padding: '0 24px',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
          '&:hover': {
            backgroundColor: '#444444',
          },
          '&.Mui-disabled': {
            backgroundColor: '#AAAAAA',
            color: '#FFFFFF',
          },
          '@media (max-width: 600px)': {
            width: 'auto',
            marginBottom: '10px',
          },
          '@media (min-width: 601px)': {
            width: 'auto',
            '&:last-child': {
              marginRight: 0,
            }
          },
          '& .MuiButton-startIcon': {
            marginRight: '12px',
          }
        }
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#f7f7f7',
          boxShadow: 'none',
          height: '80px',
        }
      }
    }
  },
  typography: {
    fontFamily: 'Galano Grotesque, sans-serif',
    color: 'rgb(105, 92, 119)',
    body1: {
      fontFamily: 'Galano Grotesque, sans-serif',
      fontSize: '0.875rem',
      color: 'rgb(105, 92, 119)',
      textOverflow: 'ellipsis',
    },
    body2: {
      fontFamily: 'Galano Grotesque, sans-serif',
      fontSize: '0.875rem',
      color: 'rgb(105, 92, 119)',
      textOverflow: 'ellipsis',
    },
    h1: {
      fontFamily: 'Galano Grotesque, sans-serif',
      color: 'rgb(105, 92, 119)',
    },
    h2: {
      fontFamily: 'Galano Grotesque, sans-serif',
      color: 'rgb(105, 92, 119)',
    },
    h3: {
      fontFamily: 'Galano Grotesque, sans-serif',
      color: 'rgb(105, 92, 119)',
    },
    h4: {
      fontFamily: 'Galano Grotesque, sans-serif',
      color: 'rgb(105, 92, 119)',
    },
    h5: {
      fontFamily: 'Galano Grotesque, sans-serif',
      color: 'rgb(105, 92, 119)',
    }
  }
});

export const LoginCentralPic = styled(Box)`
  && {
    width: 720px;
    height: 457px;
    background: url(${cardWhiteImage}) center no-repeat;
    background-size: cover;
    padding: 0;
    min-width: auto;    
  }
`;


export const UnauthenticatedMainContainer = styled(Box)({
  display: 'flex',
  height: '100vh',

  '@media (max-width: 1500px)': {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${wallpaperBackground})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    maxWidth: 'calc(100%)',
    minHeight: '100vh',
    flexDirection: 'column',
    padding: '10px',
  },

  '@media (min-width: 1501px)': {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
  },
});

export const UnauthenticatedLeftContainer = styled(Box)`
 && {
   flex: 3;
   background-image: url(${wallpaperBackground});
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;

   @media (max-width: 1500px) {
     display: none;
   }
 }
`;

export const UnauthenticatedRightContainer = ({ children, ...props }) => {
  return (
    <Box
      {...props}
      sx={{
        // Common styles for both breakpoints
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',

        // Spacing between direct children
        '& > * + *': {
          pt: '2px',
        },

        // Styles for smaller screens
        '@media (max-width: 1500px)': {
          bgcolor: '#ffffff',
          p: '20px',
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          maxWidth: '400px',
          width: 'calc(100% - 40px)',
          gap: '20px',
        },

        // Styles for larger screens
        '@media (min-width: 1501px)': {
          flex: 1,
          p: '70px',
          gap: '8px',
          maxWidth: '340px !important',
        },

        ...props.sx // Allow extending styles through sx prop
      }}
    >
      {children}
    </Box>
  );
};

export const UnauthenticatedLanguageFlagsContainer = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
  background-color: #ffffffb3;
  border-radius: 8px;
  padding: 5px 10px;
  box-shadow: 0 0 5px #0000004d;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;


export const ForgotPassword = ({ children }) => {
  return (
    <Box style={{ width: '100%', textAlign: 'right' }}>
      <Link
        href="/auth/reset-my-password"
        style={{
          fontSize: '14px',
          color: '#333',
          cursor: 'pointer'
        }}
      >
        {children}
      </Link>
    </Box>
  );
};


export const LoginLogoMobile = styled.div`
  width: 240px;  // Much wider
  height: 160px; // Taller
  
  background-image: url(${logo_big});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; // Changed from cover to contain
  
  padding: 0;
  min-width: 240px; // Added min-width
  border: none;
  outline: none;
  
  // Better image quality
  imageRendering: '-webkit-optimize-contrast';
  imageRendering: 'crisp-edges';

  @media (max-width: 1500px) {
    display: block;
    margin: 30px auto; // More margin for better spacing
  }
`;


export const TermsModal = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 300px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    width: calc(100vw - 80px);
    height: calc(100vh - 80px);
    overflow-y: auto;
    max-width: 800px;
    max-height: 800px;
`;

export const KycMainDiv = styled.div`
   background-color: #6096ba99;
   background-image: url(${wallpaperBackground_light});
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   width: 100vw;
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
   overflow-y: auto;
   margin: 0;
   padding: 0;
`;

export const KycContent = styled.div`
   height: 100%;
   width: 100%;
   position: relative;
`;

export const KycWebSdk = styled.div`
   width: 100%;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
`;

export const KycCloseButton = styled.button`
   position: absolute;
   top: 16px;
   left: 16px;
   z-index: 1000;
`;


export const OtpContainer = styled(Box)({
  display: 'flex',
  gap: '4px',
});

export const StyledOtpInput = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    border: '1px solid #ffffff !important',
    borderRadius: '5px',
    boxShadow: '3px 3px 6px #f0f0f0',

    [`@media (max-width: 1500px)`]: {
      width: '40px',
      margin: '2px',
    },

    [`@media (min-width: 1501px)`]: {
      width: '42px',
      margin: '7px',
    },
  },
});

export const ErrorContainer = styled(Box)(({ isModal }) => ({
  backgroundColor: '#ffe6e6',
  border: '1px solid #D52A20',
  borderRadius: '5px',
  paddingRight: '15px',
  paddingLeft: '15px',
  paddingTop: '10px',
  paddingBottom: '10px',
  margin: isModal ? '20px' : '10px auto',
  display: 'flex',
  alignItems: 'center',
  verticalAlign: 'middle',
  gap: '15px',
  minHeight: '50px',

  '&::before': {  // Using pseudo-element for the icon
    content: '"!"',
    backgroundColor: '#D52A20',
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 700,
  },

  // Style for the text content
  '&': {
    color: '#D52A20',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1.2,
  },
}));

export const SuccessContainer = styled(Box)(({ isModal }) => ({
  backgroundColor: '#d9f2d9',
  border: '1px solid #2e8b57',
  borderRadius: '5px',
  paddingRight: '15px',
  paddingLeft: '15px',
  margin: isModal ? '20px' : '10px 0',
  display: 'flex',
  alignItems: 'center',
  verticalAlign: 'middle',
  gap: '15px',
  minHeight: '50px',

  '&::before': {
    content: '"✓"',  // Checkmark symbol
    backgroundColor: '#2e8b57',
    borderRadius: '50%',
    width: '16px',
    height: '16px',
    flexShrink: 0,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontSize: '12px',
    fontWeight: 700,
    marginTop: '2px',
  },

  // Text styles
  '&': {
    color: '#2e8b57',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1.2,
  }
}));

export const NotificationItem = ({ message, handleReadMessage, index }) => {
  return (
    <Box
      onClick={() => handleReadMessage(message)}
      key={message.id || index}
      sx={{
        display: 'flex',
        alignItems: 'center',
        color: message.is_read ? '#695C77' : '#374F8F',
        fontSize: '0.875rem',
        fontFamily: 'Galano Grotesque, sans-serif',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        py: '10px',
        borderBottom: '1px solid #f0f0f0',
        width: '100%',
        cursor: 'pointer',
      }}
    >
      {message.is_read ? (
        <Mail sx={{ color: '#f0f0f0', mr: 1 }} />
      ) : (
        <MailOutline sx={{ color: '#374F8F', mr: 1 }} />
      )}
      {message.subject}
    </Box>
  );
};

export const NotificationsSidebarTitle = styled(Box)({
  fontWeight: 600,
  color: 'rgb(51, 51, 51)',
  fontSize: '0.875rem',
  fontFamily: 'Galano Grotesque, sans-serif',
  marginBottom: '10px'
});

export const ModalTitle = styled(DialogTitle)({
  fontFamily: 'Galano Grotesque, sans-serif',
  fontWeight: 500,
  fontSize: '1.25rem',
  lineHeight: 1.6,
  padding: '16px 24px',
  textAlign: 'center',
});

export const BeneficiaryDetailsSummary = ({ children }) => (
  <Box
    sx={{
      color: '#374F8F',
      fontSize: '0.875rem',
      fontFamily: 'Galano Grotesque, sans-serif',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      py: '10px',
      borderBottom: '1px solid #f0f0f0',
      width: '100%'
    }}
  >
    {children}
  </Box>
);

export const FDTTableFooter = (props) => {
  return (
    <TablePagination
      {...props}
      style={{
        fontFamily: "'Poppins', sans-serif",
        color: "rgb(105, 92, 119)"
      }}
      SelectProps={{
        style: { 
          '-webkit-appearance': 'none',
          '-moz-appearance': 'none',
          appearance: 'none',
          paddingRight: '8px'
        }
      }}
    />
  );
};


export const FDTTableSortLabel = (props) => {
  return (
    <TableSortLabel
      {...props}
      style={{
        fontFamily: "'Poppins', sans-serif",
        color: "rgb(105, 92, 119)"
      }}
    />
  );
};

export const CurrencyTableCell = ({ amount, isCredit }) => {
  return (
    <TableCell align="right">
      <span style={{ color: isCredit ? '#3E885B' : '#D52A20' }}>
        {!isCredit && '-'}
        {amount}
      </span>
    </TableCell>
  );
};

export const UnauthenticatedLink = styled(Link)({
  color: "#F39D52 !important",
  cursor: "pointer",
  textDecoration: "underline",
  textDecorationColor: "#F39D52 !important",
  "&:hover": {
    color: "#F39D52 !important",
    textDecorationColor: "#F39D52 !important"
  }
});

export const LoggedInTopLeftLogo = styled.div(props => ({
  background: `url(${props.$drawerReduced ? logo_small : logo_big})`,
  backgroundSize: '70px 120px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center', // centers both horizontally and vertically
  height: '140px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center', // centers horizontally in the flex container
  alignItems: 'center', // centers vertically in the flex container
  marginTop: '-10px',
  marginBottom: '-10px',
}));

export const TitleListItem = styled(ListItem)(({ theme }) => ({
  pointerEvents: 'none',
  userSelect: 'none',
  paddingLeft: '24px',
  paddingTop: '20px',
  paddingBottom: '8px',
  textTransform: 'uppercase',
  fontFamily: 'Galano Grotesque, sans-serif',
  fontSize: '12px',
  fontWeight: 600,
  color: '#374F8F',
  marginTop: '20px',
}));

export const LeftMenuItem = styled(ListItem)(({ $drawerReduced }) => ({
  fontSize: '1.5rem',
  cursor: 'pointer',
  borderRadius: '5px',
  marginBottom: '5px',
  transition: 'background-color 0.3s ease',
  flexDirection: $drawerReduced ? 'column' : 'row',
  justifyContent: $drawerReduced ? 'center' : 'flex-start',
  alignItems: 'center',
  textAlign: $drawerReduced ? 'center' : 'left',
  width: $drawerReduced ? '100%' : undefined,
  '&:hover': {
    backgroundColor: '#e0f0f9',
  },
  '&:active': {
    backgroundColor: '#c5cacf',
  },
}));
export const StructureLeftMenuItem = ({ icon: Icon, description, path, drawerReduced, location, navigate, t }) => {
  const isSelected = location.pathname.startsWith(path);
  return (
    <ListItem
      $drawerReduced={drawerReduced}
      onClick={() => navigate(path)}
      sx={theme => ({
        fontWeight: 700,
        fontSize: '1.5rem',
        cursor: 'pointer',
        borderRadius: '5px',
        marginBottom: '5px',
        transition: 'background-color 0.3s ease',
        backgroundColor: isSelected ? theme.palette.leftmenu_selected_background.main : 'transparent',
        color: isSelected ? theme.palette.leftmenu_selected_text.main : theme.palette.leftmenu_not_selected_text.main,
        flexDirection: drawerReduced ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: drawerReduced ? 'center' : 'flex-start',
        textAlign: drawerReduced ? 'center' : 'left',
        marginRight: drawerReduced ? '4px' : '16px',
        marginLeft: drawerReduced ? '4px' : '16px',
        padding: drawerReduced ? '12px 4px' : '8px 16px',
        height: '44px',
        '& .MuiListItemIcon-root': {
          minWidth: drawerReduced ? 'unset' : '36px',
          justifyContent: 'center',
          marginBottom: drawerReduced ? '8px' : '0',
          marginRight: drawerReduced ? '0' : '12px',
        },
        '& .MuiListItemText-root': {
          textAlign: drawerReduced ? 'center' : 'left',
          margin: 0,
        },
        '&:hover': {
          backgroundColor: theme.palette.leftmenu_selected_background.main,
        },
      })}
    >
      <ListItemIcon
        sx={{
          alignContent: 'center',
          minWidth: drawerReduced ? 'unset' : '36px',
          justifyContent: drawerReduced ? 'center' : 'flex-start',
          width: drawerReduced ? '100%' : 'auto',
        }}>
        <Icon color="leftmenu_icons" />
      </ListItemIcon>
      <ListItemText
        sx={{
          paddingLeft: drawerReduced ? '10px' : '0',
          paddingRight: drawerReduced ? '10px' : '0',
        }}
        primary={t(description)} 
      />
    </ListItem>
  );
};

export const UnauthenticatedTitle = styled.div({
  fontWeight: 700,
  color: '#374F8F',
  fontSize: '1.5rem',
  fontFamily: 'Galano Grotesque, sans-serif',
  lineHeight: 1.334,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px'
});

export const WaitingForMobileApproval = styled.div({
  fontWeight: 700,
  color: '#008000',
  fontSize: '1.6rem',
  fontFamily: 'Galano Grotesque, sans-serif',
  lineHeight: 1.334,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});

export const HomeCardHoverType = styled.div({
  color: "#374F8F",
  position: "absolute",
  top: "95px",
  left: "130px",
  zIndex: 10,
  fontSize: "1rem",
  width: "150px",
  textAlign: "center",
  lineHeight: "26px",
  height: "30px",
});

export const HomeCardHoverNumber = styled.div({
  position: "absolute",
  bottom: "70px",
  left: "50%",
  transform: "translateX(-50%)",
  zIndex: 55,
  color: "#374F8F",
});

export const HomeCardHoverStatus = styled.div(props => ({
  position: 'absolute',
  bottom: '25px',
  left: '20px',
  zIndex: 10,
  fontSize: '1rem',
  width: '100px',
  textAlign: 'center',
  lineHeight: '26px',
  color: (() => {
    switch (props.$current_status) {
      case 'ACTIVE':
        return '#3dc690';
      case 'INACTIVE':
      case 'BLOCKED':
      case 'SUSPEND':
      case 'TERMINATED':
        return '#e34542';
      default:
        return '#aaaaaa';
    }
  })()
}));

export const HomeWelcomeBoxLogo = styled.div({
  background: `url(${logo_big})`,
  backgroundSize: '120px auto', // Changed from 'contain'
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8
  },
  transition: 'opacity 0.2s ease',
  height: '120px', // Increased from 100px
  width: '100%', // Changed from 40px to allow the logo to take full width
  display: 'block',
  marginRight: '20px',
  // Add this for better quality
  imageRendering: '-webkit-optimize-contrast',
  imageRendering: 'crisp-edges'
});

export const SliderSettings = (currentSlide, setCurrentSlide) => ({
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  customPaging: (i) => (
    <Box
      style={{
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        backgroundColor: i === currentSlide ? '#F39D52' : '#f0f0f0',
        padding: 0,
        margin: '0 4px',  // Maintain horizontal margin between dots
        cursor: 'pointer',
        transition: 'all 0.3s ease'
      }}
    ></Box>
  ),
  dotsClass: 'slick-dots custom-dot-class',
  beforeChange: (current, next) => setCurrentSlide(next),
  appendDots: dots => (
    <Box
      style={{
        width: '100%',
        padding: '40px 0 0 0',
        margin: 0,
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <ul style={{
        display: 'flex',
        padding: 0,
        margin: 0,
        listStyle: 'none',
        gap: '12px',  // Increased gap between dots
        justifyContent: 'center'
      }}>
        {dots}
      </ul>
    </Box>
  )
});

export const LoggedInCentralContainer = styled(Box)(({ theme, $isMobile, $drawerReduced }) => ({
  position: 'relative',
  height: 'calc(100vh - 120px)',
  overflowY: 'auto',
  marginTop: '20px',
  paddingBottom: '20px',
  display: 'flex',
  flexDirection: 'column', // Added to maintain content layout

  ...($isMobile ? {
    marginLeft: 0,
    width: 'calc(100vw - 40px)',
    paddingLeft: '20px',
    paddingRight: '20px',
    textAlign: 'center',
  } : {
    marginLeft: $drawerReduced ? '140px' : '250px', // Added px for consistency
    width: $drawerReduced ? 'calc(100% - 150px)' : 'calc(100% - 280px)',
    color: '#FFFFFF',
    paddingLeft: $drawerReduced ? '0px' : '10px',
    paddingRight: $drawerReduced ? '0px' : '20px',
    marginRight: 'auto',
    transition: 'all 0.2s ease', // Smooth transition for drawer changes
  }),
}));